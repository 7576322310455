
.about-us-page {


    /* CSS to elevate the central card and put others behind it */

    .review-card {
        transition: transform 0.5s ease, box-shadow 0.5s ease;
    //padding: 20px; background-color: #f5f5f5;
        border-radius: 8px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        text-align: left;
        position: relative; /* Set position to relative to allow z-index to take effect */
        z-index: 0; /* Default z-index for non-centered cards */

        .card_body {
            margin: 16px;
        }
    }

    .slick-center .review-card {
        transform: scale(1.2) translateY(-20px); /* Elevate and enlarge the central card */
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
        position: relative; /* Set position to relative to allow z-index to take effect */
        z-index: 3; /* Higher z-index to ensure it appears above the other cards */
    }

    .slick-track {
        padding-top: 20px;
        height: 310px;
    }

    .slick-slide:not(.slick-center) .review-card {
        transform: scale(0.85) perspective(1000px) rotateY(0deg);
        opacity: 0.7;
        position: relative; /* Set position to relative to allow z-index to take effect */
        z-index: 0;
    }

    /* Optional: Adjust the slick dots for better emphasis on the active card */

    .slick-dots li button:before {
        color: #888;
    }

    .slick-dots li.slick-active button:before {
        color: #333;
    }

    .review-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .review-author {
        font-weight: bold;
        font-size: 1.1em;
    }

    .review-date {
        margin: 10px 0;
        color: #888;
    }

    .review-text {
        color: #333;
        font-size: 1em;
    }

    .review-rating {
        color: #ff5722;
        font-size: 1.2em;
    }

    .slick-center .review-card {
        transform: scale(1.1) perspective(1000px) rotateY(0deg);
    }

    .slick-slide:not(.slick-center) .review-card {
        transform: scale(0.85) perspective(1000px) rotateY(0deg);
        opacity: 0.7;
    }

    /* Customize slick dots */

    .slick-dots li button:before {
        color: #888;
    }

    .slick-dots li.slick-active button:before {
        color: #333;
    }

    .about-us-page-reviews--why-we {
        .slick-next:before {
            content: '';
        }

        .slick-slider .slick-arrow {
            display: flex !important;
            justify-content: center;
            align-items: center;
        }
    }


    .custom-arrow {
        height: 100%;
        width: 60px;
        background: white !important;
        z-index: 2;
    }

    @media (max-width: 768px) {
        .about-us-page-reviews--why-we {
            .slick-slider .slick-arrow {
                display: none !important;

            }
        }
    }

}