

.mobile-menu-open{
    .mobile-page-filters--wrap{
         position: initial;
    }
}


.mobile-navigation--layer{
    //padding-top: 20px;
}
.mobile-navigation{
    position: absolute;
    width: 100%;
    top: 38px;
    background-color: rgba(211, 211, 211, 0.5);
}
.wrap-nav{
    background: white;
    margin-left: 20%;
    height: calc(100vh - 40px - env(safe-area-inset-bottom));
    overflow: scroll;
}
/* Animate the burger lines */
.burger-menu.open span:nth-child(1) {
    transform: rotate(45deg) translateY(8px);
}

.burger-menu.open span:nth-child(2) {
    opacity: 0;
}

.burger-menu.open span:nth-child(3) {
    transform: rotate(-45deg) translateY(-8px);
}

.burger-menu span {
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.nav-links{
    padding-left: 0;
    .why-you--cell{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding:  0 10px;
        .why-you--cell_title{
            font-weight: bold;
            font-size: 0.8375rem;
            line-height: 1.5rem;
        }
    }

    li{
        width: 100%;
        &.active{

            border-bottom: 1px solid grey;
        }

    }
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem;
    height: 100vh;
    //background-color: #333;
    //color: white;
}

.nav-links {
    list-style: none;
    display: flex;
    gap: 1rem;
}

.nav-links a {
    text-decoration: none;
    //color: white;
    font-size: 1rem;
}

/* Burger Menu Styles */
.burger-menu {
    display: none;
    flex-direction: column;
    cursor: pointer;
    gap: 0.5rem;
}

.burger-menu span {
    width: 25px;
    height: 3px;
    //background-color: white;
    border-radius: 5px;
    transition: 0.3s;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .mobile-navigation--layer{
    //padding-top: 20px;
    }
    .burger-menu {
        display: flex;
    }

    .nav-links {
        //position: absolute;
        //top: 100%;
        //right: 0;
        //background-color: #333;
        //flex-direction: column;
        //align-items: center;
        width: 100%;
         height: 100%;
        flex-direction: column;
        align-items: flex-end;
        display: flex;
        //overflow: hidden;
        transition: max-height 0.3s ease-in-out;
    }

    .nav-links.active {
        max-height: 300px; /* Adjust as needed */
    }

    .nav-links li {
        padding: 1rem 0;
    }
}
