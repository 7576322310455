/* -== Bread Crumb ==- */
@import "../base/root.pcss";
.bread-crumb--wrap {
  margin: 16px 0;

  .link-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;

    .link-item {
      position: relative;

      a {
        display: block;
        font-weight: normal;
        font-family: var(--fonts-regular);
        font-size: 12px;
        line-height: 1;
        color: var(--text-gray-1);
        padding: 0 6px;
        text-decoration: none;
      }

      &:first-of-type {
        a {
          padding-left: 0;
        }
      }

      &:not(:first-of-type) {
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 1px;
          background: #dcd9d9;
        }
      }

      &:last-of-type {
        a {
          color: var(--text-dark);
          font-family: var(--font-medium);
        }

        &:before {
          background: #000000 !important;
        }
      }

      &:not(:last-of-type) {
        a {
          &:hover {
          }
        }
      }
    }
  }
}

.react-tel-input {
  border-bottom: solid 1px lightgray;
  .form-control {
    padding-left: 10px;
    width: 100%;
    border: none !important;
    font-family: var(--fonts-regular), Roboto, Arial, sans-serif;
  }
  .form-control:focus {
    border-color: transparent;
    box-shadow: none;
  }

  .flag-dropdown {
    display: none;
  }
}

.dialog-title {
  font-family: var(--fonts-regular), Roboto, Arial, sans-serif;
}
.top-sale--cell.flex-center {
  display: flex;
  justify-content: center;
}

.top-sale {
  &--dialog {
    width: 75%;
  }
  button:disabled,
  button[disabled] {
    color: lightgray !important;
    //background: hsl(12deg 39.58% 49.08%) !important;
  }
}

.ratingBlock {
  text-align: center;
  padding-top: 20px;
}

.rating {
  &-label {
    &--button {
      background: var(--base-orange);
      color: var(--base-white);
      background: var(--base-orange);
      border: 1px solid var(--base-orange);
      outline: none;
      box-shadow: none;
      font-size: 16px;
      border-radius: 19px;
      font-weight: lighter;
      //font-family: Montserrat-Thin;
      //line-height: 1.0625rem;
      font-family: var(--fonts-regular);
      //text-align: center;
      display: block;
      box-sizing: border-box;
      cursor: pointer;
      position: absolute;
    }
    &--star {
      position: relative;
      top: 1px;
    }
  }
}
.realtor {
  .rating-label-wrap {
    position: absolute;
    float: right;
    z-index: 1;
    margin-left: 90px;
    margin-top: -40px;
  }
  .rating {
    &-label {
      &--button {
        width: 60px;
        //position: absolute;
        //left: 120px;
        //top: 194px;
        //left: 128px;
      }
    }
  }
}
